/**
 * @description 开发环境配置
 */
const development = {
    server: {
        // 服务端url
        baseurl: 'https://crqyy.carecancer.cn',
        // socket url
        socketurl: "wss://crqyy.carecancer.cn",
        // 普通二维码路径
        qrcodePrefix: "/testweixin"
    },
    microApps: [{
        url: "http://localhost:9001/",
        name: "doctor",
        path: "/doctor",
        description: "医生端后台管理系统"
    }]
}


/**
 * @description 生产环境配置
 */
const prod = {
    server: {
        // 服务端url
        baseurl: 'https://crqyy.carecancer.cn',
        // socket url
        socketurl: "wss://crqyy.carecancer.cn",
        // 普通二维码路径
        qrcodePrefix: "/testweixin"
    },
    microApps: [{
        url: "https://crqyy-doctor.carecancer.cn",
        name: "doctor",
        path: "/doctor",
        description: "医生端后台管理系统"
    }]
}
// const prod = {
//     server: {
//         baseurl: 'https://app.carecancer.cn',
//         socketurl: "wss://app.carecancer.cn"
//     },
//     microApps: [{
//         url: "http://doctor.carecancer.cn",
//         name: "doctor",
//         path: "/doctor",
//         description: "医生管理后端"
//     }]
// }
/**
 * @description 获取api path
 */
const getApiPath = () => {
    return process.env.NODE_ENV === 'development' ? development.server.baseurl : prod.server.baseurl
}

const getApplication = () => {
    return process.env.NODE_ENV === 'development' ? development.server : prod.server
}

/**
 * @description 获取socket url
 */
const getSocketPath = () => {
    return process.env.NODE_ENV === 'development' ? development.server.socketurl : prod.server.socketurl
}

export { development, prod, getApiPath, getSocketPath, getApplication };